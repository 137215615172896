import CircledCheckMark from "../../assets/icons/CircledCheckMark";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import Typography from "../Typography";
import styles from "./styles.module.scss";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import BackButton from "../BackButton";

const UnlockGrowthBanner = () => {
  const currentPlan = useCurrentPlan();
  const items = currentPlan?.items?.split("\n");
  const isDesktopMode = useIsDesktopMode();

  return (
    <div className={styles.wrapper}>
      {!isDesktopMode && (
        <BackButton
          showLogo={true}
          style={{ padding: "25px", width: "100%" }}
        />
      )}
      <div className={styles.footer_title}>
        <h1>
          Unlock Relationship Growth
          {!isDesktopMode ? <br /> : " "}
          With Us
        </h1>{" "}
        {isDesktopMode && <p>Here’s what you’ll get:</p>}
      </div>
      <ul className={styles.list}>
        {items?.map((item) => (
          <li key={item}>
            <CircledCheckMark />
            <Typography
              style={{
                color: "#31232F",
              }}
            >
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UnlockGrowthBanner;
