import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetDesktopStaticContentQuery,
  useGetPricesQuery,
} from "../../../api/cms";
import useCurrentTier from "../../../hooks/useCurrentTier";
import { NEW_ROUTES } from "../../../router";
import SectionContent from "./SectionContent";
import styles from "./styles.module.scss";
import useCurrentStep, { Plans, Steps } from "../../../hooks/useCurrentStep";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getMatchingFromSearchParams,
  getPaymentModeFromSearchParams,
  getSubscriptionFromSearchParams,
} from "../../../utils/pricing-content";
import { useGetBackendPlansQuery } from "../../../api/management";
import { findMatchedPlan } from "../../../utils/getMatchedPlan";
import useRedirect from "../../../hooks/useRedirect";
import Loading from "../../../components/Loading";
import Discount from "../../../assets/icons/Discount";
import { Typography } from "../../../components";
import useCurrentPlan, { Subscriptions } from "../../../hooks/useCurrentPlan";

export const STEP_COUNT = 3;

const MainSection = () => {
  const { data: content } = useGetDesktopStaticContentQuery();
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const currentTier = useCurrentTier();
  useGetPricesQuery(currentTier?.id, { skip: !currentTier });
  const { redirect: newRedirect, isLoading } = useRedirect();
  const step = useCurrentStep();
  const subscription = getSubscriptionFromSearchParams(search);
  const { matchingPricing, newBillingCycle } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const { data: backendPlans } = useGetBackendPlansQuery();
  const plan = useCurrentPlan();
  const selectedSubscription =
    subscription ||
    (plan?.name === Subscriptions.Individual ? Plans.Individual : plan?.name);
  const matchingPlan = findMatchedPlan(
    selectedSubscription,
    state,
    backendPlans,
  );
  const Routes = {
    Individual: NEW_ROUTES.SELECT_INDIVIDUAL_PLAN,
    Couple: NEW_ROUTES.SELECT_COUPLE_PLAN,
  };
  const currentStep = useCurrentStep();
  const mode = getPaymentModeFromSearchParams();

  const handleContinueClick = async () => {
    if (step + 1 >= STEP_COUNT) {
      const payload = {
        tier: currentTier?.tier_type,
        price:
          newBillingCycle && matchingPlan
            ? matchingPlan.stripe_price_id
            : state?.price || "",
        postgres_plan_id:
          newBillingCycle && matchingPlan
            ? matchingPlan.uuid
            : state?.postgres_plan_id || 0,
      };
      await newRedirect(payload);
    } else {
      if (matchingPricing && matching) {
        navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
          state: {
            tier: state?.plan_match.default_tier,
            plan: state?.plan_match,
            step: 2,
          },
        });
      } else {
        navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
          state: { ...state, step: step + 1 },
        });
      }
    }
  };

  const showBackButton = step === 2;

  const handleBackClick = () => {
    if (matching && matchingPricing) {
      navigate(NEW_ROUTES.PLANS, {
        state: { ...state, step: 0 },
      });
    } else {
      navigate(subscription ? Routes[subscription] : NEW_ROUTES.HOME, {
        state: { ...state, step: step - 1 },
      });
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div className={styles.main_section}>
      {matchingPricing && matching && !mode && (
        <div className={styles.matching_discount}>
          <Discount />
          <Typography>Matching session discount applied</Typography>
        </div>
      )}
      <SectionContent />
      <div className={styles.buttons_wrapper}>
        {showBackButton && (
          <button
            onClick={handleBackClick}
            disabled={!step}
            className={`${styles.btn} ${styles.back_button} ${styles.new_back_button}`}
            id="stepper_back_button"
          >
            {content?.back_button}
          </button>
        )}
        <button
          className={`${styles.btn} ${styles.new_continue_button}`}
          onClick={handleContinueClick}
          id="stepper_continue_button"
        >
          {matching &&
          matchingPricing &&
          !mode &&
          currentStep === Steps.SELECT_PAYMENT_PLAN
            ? "Upgrade"
            : content?.button}
        </button>
      </div>
    </div>
  );
};

export default MainSection;
