import React from "react";
import { useGetPriceQuery } from "../../api/stripe";
import OriginalPrice from "../OriginalPrice";
import TierFeatures from "../TierFeatures";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { Plans } from "../../hooks/useCurrentStep";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getMatchingFromSearchParams,
  getPaymentModeFromSearchParams,
  getSubscriptionFromSearchParams,
} from "../../utils/pricing-content";
import { Typography } from "../index";

interface TierCardDesktopProps {
  title: string;
  subtitle: string;
  selected: boolean;
  initialPrice: string;
  image: string;
  length: number;
  features: string;
  frequency: string;
}

const TierCardDesktop = ({
  subtitle,
  selected,
  initialPrice,
  image,
  length,
  features,
  frequency,
}: TierCardDesktopProps) => {
  const { data: price, originalPrice } = useGetPriceQuery(initialPrice);
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const isDesktopMode = useIsDesktopMode();
  const { newBillingCycle, matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const mode = getPaymentModeFromSearchParams();

  return (
    <div
      className={`${styles.wrapper} ${
        selected ? styles["wrapper-selected"] : ""
      } ${length <= 1 ? styles["wrapper-single"] : ""} ${
        styles.wrapper_repackaging
      }`}
    >
      <div className={styles.header_wrapper}>
        <div className={styles.card_title_block}>
          <div>
            {frequency && <h4 className={styles.plan_title}>{frequency}</h4>}
            <h1
              className={`${styles.title} ${
                subscription === Plans.Couple ? styles.new_pricing_title : ""
              }`}
            >
              {subscription}
            </h1>
          </div>
          <div className={styles.price_description}>
            <p className={styles.starting_at}>Starting at</p>
            {!newBillingCycle && (
              <div className={`${styles.price} ${styles.price_repackaging}`}>
                <OriginalPrice price={price} originalPrice={originalPrice} />
                {!isDesktopMode ? (
                  <h1 className={styles.title}>${price}</h1>
                ) : (
                  <h2>${price}</h2>
                )}
                <p>{`${isDesktopMode ? "per" : "/"} month`}</p>
              </div>
            )}
            {newBillingCycle && (
              <div>
                <div className={`${styles.price} ${styles.price_repackaging}`}>
                  <OriginalPrice price={price} originalPrice={originalPrice} />
                  {!isDesktopMode ? (
                    <h1 className={styles.title}>${price / 4}</h1>
                  ) : (
                    <h2>${price / 4}</h2>
                  )}
                  <p>{`${isDesktopMode ? "per" : "/"} week`}</p>
                </div>

                {matching && matchingPricing && !mode && (
                  <Typography style={{ fontWeight: 400, fontSize: "12px" }}>
                    {originalPrice / 4}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
        {isDesktopMode && <img src={image} alt="card_image" />}
      </div>
      <hr className={styles.divider} />
      <p
        style={{ paddingRight: length <= 1 ? 0 : "32px" }}
        className={styles.description}
      >
        {subtitle}
      </p>
      {isDesktopMode && <TierFeatures features={features} />}
    </div>
  );
};

export default TierCardDesktop;
