import React from "react";
import Typography from "../Typography";
import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getMatchingFromSearchParams } from "../../utils/pricing-content";

const Title = ({ variant }: { variant?: any }) => {
  const firstName = new URLSearchParams(window.location.search).get(
    "first_name",
  );
  const { search } = useLocation();
  const { matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const matchingUpgradeTitle =
    matchingPricing &&
    matching &&
    "Sign up for Individuals or Couples Weekly session";
  const pricingTitle = (
    <>{firstName && <span>{firstName},</span>} Here’s Your Recommended Plan</>
  );

  return (
    <Typography
      style={{ marginTop: "10px" }}
      variant={variant || "large-header"}
    >
      {matchingUpgradeTitle || pricingTitle}
    </Typography>
  );
};

export default Title;
