import React from "react";
import styles from "./styles.module.scss";
import { Button, Typography } from "../index";
import ErrorMessage from "../ErrorMessage";
import { PaymentElement } from "@stripe/react-stripe-js";
import {
  getEmailFromSearchParams,
  getIsMatchingOrUpgrading,
} from "../../utils/pricing-content";
import { PaymentType } from "../../utils/payment";
import MoneyBackBanner from "../MoneyBackBanner";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";

interface PaymentDetailsFormProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  error: string;
  name: string;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  type?: PaymentType;
}

const PaymentDetailsForm = ({
  handleSubmit,
  error,
  onNameChange,
  isLoading,
  name,
  type,
}: PaymentDetailsFormProps) => {
  const email = getEmailFromSearchParams();
  const isDesktopMode = useIsDesktopMode();
  const isMatchingOrUpgrading = getIsMatchingOrUpgrading();
  return (
    <div className={styles.content}>
      {isDesktopMode && (
        <Typography
          variant="subtitle"
          style={{ fontWeight: 600, marginBottom: "20px", color: "#31232f" }}
        >
          Contact Information
        </Typography>
      )}
      <div className={styles.email}>
        <Typography
          variant="subtitle"
          style={{ fontWeight: 500, marginRight: "20px" }}
        >
          Email
        </Typography>
        <Typography
          variant="subtitle"
          style={{
            fontWeight: 500,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            textAlign: "right",
          }}
        >
          {email}
        </Typography>
      </div>

      <Typography
        variant="subtitle"
        style={{ fontWeight: 600, color: "#31232f" }}
      >
        {type || PaymentType.Payment}
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit}>
        {error && <ErrorMessage error={error} />}
        <label className={styles.label}>
          Cardholder name
          <input
            type="text"
            value={name}
            onChange={onNameChange}
            placeholder="Full Name"
            className={styles.input}
          />
        </label>
        <PaymentElement
          options={{
            fields: {
              billingDetails: {
                name: "never",
              },
            },
          }}
        />
        <Button
          id="purchase-button"
          onClick={() => {}}
          style={{ marginTop: "30px", borderRadius: "100px" }}
          disabled={isLoading}
        >
          {isLoading
            ? "Processing..."
            : type !== PaymentType.UpdatePaymentDetails
              ? "Purchase"
              : "Submit"}
        </Button>
      </form>
      {!isMatchingOrUpgrading && <MoneyBackBanner />}
    </div>
  );
};

export default PaymentDetailsForm;
