import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import useCurrentTier from "../../hooks/useCurrentTier";
import { useGetPricesQuery } from "../../api/cms";
import {
  useGetMostExpensivePriceQuery,
  useGetPriceQuery,
} from "../../api/stripe";
import {
  getMatchingFromSearchParams,
  getSubscriptionFromSearchParams,
} from "../../utils/pricing-content";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import { NEW_ROUTES } from "../../router";
import PriceCard from "../PriceCard";

const PricesList = () => {
  const tier = useCurrentTier();
  const { data: prices } = useGetPricesQuery(
    { tierId: tier?.id },
    { skip: !tier }
  );
  const { data: initialPrice } = useGetPriceQuery(tier?.initial_price);
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const subscription = getSubscriptionFromSearchParams(search);
  const isDesktopMode = useIsDesktopMode();
  const { matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const mostExpensivePrice = useGetMostExpensivePriceQuery(
    prices?.map((item) => item.stripe_id) || []
  );

  useEffect(() => {
    if (prices && !state?.price) {
      const defaultPrice = prices.length >= 2 ? prices[1] : prices[0];
      navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
        state: {
          ...state,
          price: defaultPrice?.stripe_id,
          months: defaultPrice?.months,
          postgres_plan_id: defaultPrice?.postgres_plan_id,
        },
      });
    }
  }, [prices, state, navigate, subscription, matching, matchingPricing]);

  return (
    <div
      className={`${styles.prices_list_wrapper} ${
        !isDesktopMode && styles.new_pricing_wrapper
      }`}
    >
      {prices?.map((item) => (
        <div
          key={item.id}
          id={`price_item-${item.id}`}
          onClick={() =>
            navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
              state: {
                ...state,
                price: item.stripe_id,
                months: item.months,
                postgres_plan_id: item.postgres_plan_id,
              },
            })
          }
        >
          <PriceCard
            title={item.title}
            priceId={item.stripe_id}
            cardLabel={item.label}
            months={item.months}
            initialPrice={initialPrice || 0}
            selected={item.stripe_id === state?.price}
            mostExpensivePrice={mostExpensivePrice}
          />
        </div>
      ))}
    </div>
  );
};

export default PricesList;
