import React from "react";
import styles from "./styles.module.scss";
import { Button, Typography } from "../../components";
import { NEW_ROUTES } from "../../router";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import useCurrentTier from "../../hooks/useCurrentTier";
import { useLocation, useNavigate } from "react-router-dom";
import MoneyBackBanner from "../../components/MoneyBackBanner";
import TiersList from "../../components/Tiers";
import MatchingDiscount from "../../components/MatchingDiscount";
import { getFirstNameFromQueryParams } from "../../utils/getCustomerData";

const MobileLayout = () => {
  const plan = useCurrentPlan();
  const tier = useCurrentTier();
  const { state } = useLocation();
  const navigate = useNavigate();
  const firstName = state?.firstName || getFirstNameFromQueryParams();

  return (
    <div className={styles.plan_wrapper}>
      <MatchingDiscount />
      <Typography variant="header" style={{ margin: "10px 0 20px" }}>
        {firstName && <span>{firstName},</span>} Here’s Your Recommended Plan
      </Typography>
      <TiersList />
      <MoneyBackBanner />

      <Button
        sticky={true}
        onClick={() =>
          navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
            state: {
              tier: state?.tier || tier,
              plan: state?.plan || plan,
            },
          })
        }
      >
        Continue
      </Button>
    </div>
  );
};

export default MobileLayout;
