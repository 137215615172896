import React from "react";
import Quotes from "../../assets/icons/Quotes";
import Typography from "../Typography";
import styles from "./styles.module.scss";

interface ReviewProps {
  review?: string;
  rate?: string;
  name?: string;
}

const ReviewCard = ({ review, rate, name }: ReviewProps) => {
  return (
    <div className={styles.wrapper}>
      <Quotes />
      <Typography variant="subtitle" style={{ fontSize: 14 }}>
        {review}
      </Typography>
      <img src={rate} alt="rate" />
      <Typography variant="subtitle" style={{ fontSize: 14 }}>
        {name}
      </Typography>
    </div>
  );
};

export default ReviewCard;
