import React from "react";
import styles from "./styles.module.scss";
import { Button, Typography } from "../../components";
import { NEW_ROUTES } from "../../router";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import { useLocation, useNavigate } from "react-router-dom";
import PlansList from "../../components/Plans";
import MatchingDiscount from "../../components/MatchingDiscount";

const MobileLayout = () => {
  const plan = useCurrentPlan();
  const { state } = useLocation();
  const navigate = useNavigate();

  const new_plan = state?.plan_match ? state.plan_match : plan;

  return (
    <div className={styles.plan_wrapper}>
      <MatchingDiscount />
      <Typography
        style={{
          margin: "20px 0 20px",
          fontFamily: "Noto Serif",
          fontWeight: 500,
          fontSize: "24px",
        }}
        variant="header"
      >
        Sign up for individual or couples' weekly sessions
      </Typography>
      <PlansList />
      <Button
        sticky={true}
        onClick={() =>
          navigate(NEW_ROUTES.SELECT_PAYMENT_PLAN, {
            state: {
              tier: new_plan?.default_tier,
              plan: new_plan,
            },
          })
        }
      >
        {`Continue with ${new_plan?.name}`}
      </Button>
    </div>
  );
};

export default MobileLayout;
