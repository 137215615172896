import React, { useEffect } from "react";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { getPlatform } from "./utils/getPlatform";
import AffiliateBanner from "./components/AffiliateBanner";
import { useRoutes } from "react-router-dom";
import { newRoutes } from "./router";
import RitualAnalytics from "client-analytics";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const router = useRoutes(newRoutes);

  useEffect(() => {
    RitualAnalytics.initialize({
      trackClicks: true,
      trackViews: true,
      clickEventName: "pricing_page_clicked",
      source: "pricing_app",
    });

    window.addEventListener("matchingEvent", (event: any) => {
      if (event.detail) {
        const { event: event_name, ...body } = event.detail;
        RitualAnalytics.track(event_name, body);
      }
    });
  }, []);

  return <>{router}</>;
}

const AppWithProvider = () => (
  <>
    <AffiliateBanner />
    <ScrollToTop />
    <App />
  </>
);

const queryParams = new URLSearchParams(window.location.search);

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ID as string,
  context: {
    kind: "user",
    key: queryParams.get("uuid") || "NA",
    email: queryParams.get("email") || "NA",
    platform: getPlatform(),
  },
})(AppWithProvider);
