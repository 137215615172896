import { useGetPriceQuery } from "../../api/stripe";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { Plans } from "../../hooks/useCurrentStep";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import React, { useContext } from "react";
import {
  getMatchingFromSearchParams,
  getPaymentModeFromSearchParams,
  getPriceWithCoupon,
  getSubscriptionFromSearchParams,
} from "../../utils/pricing-content";
import { CouponContext } from "../../contexts/CouponContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Typography } from "../index";
import TierFeatures from "../TierFeatures";

interface PlanCardDesktopProps {
  title: string;
  subtitle: string;
  selected: boolean;
  initialPrice: string;
  image: string;
  length: number;
  features: string;
}

const PlanCardDesktop = ({
  title,
  subtitle,
  selected,
  initialPrice,
  image,
  length,
  features,
}: PlanCardDesktopProps) => {
  const { data: price } = useGetPriceQuery(initialPrice);
  const { coupon } = useContext(CouponContext);
  const priceWithCoupon = getPriceWithCoupon(price, coupon);
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const isDesktopMode = useIsDesktopMode();
  const matching = getMatchingFromSearchParams(search);
  const mode = getPaymentModeFromSearchParams();
  const { matchingPricing, billingCycleRollout: billingCycle } = useFlags();
  const finalPriceWithCoupon = billingCycle
    ? Math.ceil(priceWithCoupon / 4)
    : priceWithCoupon;

  return (
    <div
      className={`${styles.wrapper} ${
        selected ? styles["wrapper-selected"] : ""
      } ${length <= 1 ? styles["wrapper-single"] : ""} ${
        styles.wrapper_repackaging
      }`}
    >
      <div className={styles.header_wrapper}>
        <div className={styles.card_title_block}>
          <div>
            <h4 className={styles.plan_title}>Weekly</h4>
            <h1
              className={`${styles.title} ${
                subscription === Plans.Couple ? styles.new_pricing_title : ""
              }`}
            >
              {title}
            </h1>
          </div>
          <div className={styles.price_description}>
            <p className={styles.starting_at}>Starting at</p>
            <div className={`${styles.price} ${styles.price_repackaging}`}>
              {!isDesktopMode ? (
                <h1 className={styles.title}>${finalPriceWithCoupon}</h1>
              ) : (
                <h2>${finalPriceWithCoupon} </h2>
              )}
              <p
                style={{ position: "static", color: "#31232F" }}
              >{`${isDesktopMode ? "per" : "/"} week`}</p>
            </div>
            {matching && matchingPricing && !mode && (
              <Typography variant="strikethrough-subtitle">
                {`$${price / 4} per week`}
              </Typography>
            )}
            {!billingCycle && (
              <p className={styles.first_month}>{`${
                isDesktopMode ? "per" : ""
              } first month`}</p>
            )}
          </div>
        </div>
        {isDesktopMode && <img src={image} alt="card_image" />}
      </div>
      <p
        style={{ paddingRight: length <= 1 ? 0 : "32px" }}
        className={styles.description}
      >
        {subtitle}
      </p>
      {isDesktopMode && <TierFeatures features={features} />}
    </div>
  );
};

export default PlanCardDesktop;
